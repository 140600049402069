var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.jobs.length)?_c('CRow',_vm._l((_vm.jobs),function({
          job,
          actions,
          sub_status,
          job_applied,
          status,
          reference_type_id,
          matched_comments,
          status_id,
          sub_status_id,
        },index){return _c('CCol',{key:job.job_id,attrs:{"lg":"4"}},[_c('CCard',{staticClass:"job-card",class:{
          'job-active': _vm.isSelectedJob(job.job_id),
          'job-pending': _vm.isPendingJob(job),
        }},[_c('CCardBody',{staticClass:"p-1 pt-2 position-relative"},[(
              [_vm.Role.customerAdmin, _vm.Role.customerRecruiter].includes(
                _vm.currentUserRole
              )
            )?_c('div',{staticClass:"action-icon cursor-action d-flex"},[_c('div',{staticClass:"fav-action mx-2"},[(_vm.getMatched(index))?_c('span',{staticClass:"f-1_5 action position-relative multi-select-icon-div"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.getFavourite(index)),expression:"getFavourite(index)"},{name:"c-tooltip",rawName:"v-c-tooltip",value:(`Click to Remove favourite Job`),expression:"`Click to Remove favourite Job`"}],staticClass:"text-primary fas fa-heart",on:{"click":function($event){return _vm.removeFromFavourite(index)}}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getFavourite(index)),expression:"!getFavourite(index)"},{name:"c-tooltip",rawName:"v-c-tooltip",value:(`Click to Add favourite Job`),expression:"`Click to Add favourite Job`"}],staticClass:"far fa-heart",on:{"click":function($event){return _vm.addToFavourite(index)}}})]):_vm._e()]),(_vm.getActions(actions) && _vm.getActions(actions).length)?_c('CDropdown',{class:{ 'disabled-menu': !_vm.getActions(actions).length },attrs:{"disabled":!_vm.getActions(actions).length,"placement":"top-start"},scopedSlots:_vm._u([{key:"toggler",fn:function(){return [_c('div',{staticClass:"px-2"},[_c('i',{staticClass:"fas fa-ellipsis-v"})])]},proxy:true}],null,true)},_vm._l((_vm.getActions(actions)),function(data){return _c('a',{key:data.action_id,staticClass:"dropdown-item cursor-action",class:{
                  'positive-action': _vm.checkPositiveAction(
                    data,
                    status,
                    sub_status
                  ),
                },on:{"click":function($event){return _vm.openPopup({ index, data })}}},[_vm._v(" "+_vm._s(data.label)+" ")])}),0):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex info-section"},[([4, 3].includes(reference_type_id))?_c('span',{staticClass:"d-flex align-items-end"},[_c('i',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: `${matched_comments}`,
                  appendToBody: true,
                }),expression:"{\n                  content: `${matched_comments}`,\n                  appendToBody: true,\n                }"}],staticClass:"fa-solid fa-circle-info"})]):_vm._e()]),_c('div',{staticClass:"content-job-title cursor-action p-2 f-12",on:{"click":function($event){return _vm.getToJobDetails(index)}}},[_c('div',{staticClass:"job-content"},[_c('div',{staticClass:"image-wrapper"},[_c('img',{staticClass:"rounded-circle",staticStyle:{"width":"65px"},attrs:{"src":_vm.getOrganisationLogo(job)},on:{"error":function($event){$event.target.src = '/img/hospital.png';
                    $event.target.style = 'width:50px';}}})]),_c('b',{staticClass:"d-block"},[_vm._v(_vm._s(job.job_title))]),_c('span',{staticClass:"d-block"},[_c('span',{staticClass:"text-muted"},[_vm._v("ID:")]),_vm._v(" "+_vm._s(job.display_uid)+" ")]),_c('span',{staticClass:"d-block"}),(job.organisation.name)?_c('span',{staticClass:"d-block text-muted"},[_vm._v(_vm._s(job.organisation.name))]):_vm._e(),_c('span',{staticClass:"text-muted"},[_c('span',{staticClass:"icon-location"}),_vm._v(" "+_vm._s(_vm.getHospitalLocation(job))+" ")])]),_c('div',{staticClass:"text-left mb-3 ml-2",staticStyle:{"font-size":"medium"}},[(job_applied)?_c('CBadge',{staticClass:"font-weight-normal ml-1",attrs:{"color":"success"}},[_c('span',[_vm._v("Applied")])]):_c('CBadge',{staticClass:"font-weight-normal ml-1",attrs:{"color":"primary"}},[_c('span',[_vm._v("Talent Pool")])]),(status && status['display_text'])?_c('CBadge',{staticClass:"font-weight-normal ml-1 text-white",attrs:{"color":_vm.getStatusColor(status_id)}},[_c('span',[_vm._v(_vm._s(status["display_text"]))])]):_vm._e(),(
                  sub_status &&
                  sub_status['display_text'] &&
                  !_vm.getFavourite(index)
                )?_c('CBadge',{staticClass:"font-weight-normal ml-1 text-white",attrs:{"color":_vm.getStatusColor(sub_status_id)}},[_c('span',[_vm._v(_vm._s(sub_status["display_text"]))])]):_vm._e()],1)])])],1)],1)}),1):(_vm.isMatchedTab)?_c('span',{staticClass:"text-center"},[_c('p',{staticClass:"text-gray h5"},[_vm._v("No Job Matches Found")])]):_c('p',{staticClass:"text-gray h5 text-center"},[_vm._v("No Jobs Found")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }