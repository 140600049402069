<template>
  <div>
    <CRow v-if="jobs.length">
      <CCol
        lg="4"
        v-for="(
          {
            job,
            actions,
            sub_status,
            job_applied,
            status,
            reference_type_id,
            matched_comments,
            status_id,
            sub_status_id,
          },
          index
        ) in jobs"
        :key="job.job_id"
      >
        <CCard
          class="job-card"
          :class="{
            'job-active': isSelectedJob(job.job_id),
            'job-pending': isPendingJob(job),
          }"
        >
          <CCardBody class="p-1 pt-2 position-relative">
            <div
              class="action-icon cursor-action d-flex"
              v-if="
                [Role.customerAdmin, Role.customerRecruiter].includes(
                  currentUserRole
                )
              "
            >
              <div class="fav-action mx-2">
                <span
                  class="f-1_5 action position-relative multi-select-icon-div"
                  v-if="getMatched(index)"
                >
                  <i
                    v-show="getFavourite(index)"
                    class="text-primary fas fa-heart"
                    v-c-tooltip="`Click to Remove favourite Job`"
                    @click="removeFromFavourite(index)"
                  ></i>
                  <i
                    v-show="!getFavourite(index)"
                    class="far fa-heart"
                    v-c-tooltip="`Click to Add favourite Job`"
                    @click="addToFavourite(index)"
                  ></i>
                </span>
              </div>
              <CDropdown
                v-if="getActions(actions) && getActions(actions).length"
                :disabled="!getActions(actions).length"
                :class="{ 'disabled-menu': !getActions(actions).length }"
                placement="top-start"
              >
                <template #toggler>
                  <div class="px-2">
                    <i class="fas fa-ellipsis-v"></i>
                  </div>
                </template>
                <a
                  class="dropdown-item cursor-action"
                  v-for="data in getActions(actions)"
                  :key="data.action_id"
                  @click="openPopup({ index, data })"
                  :class="{
                    'positive-action': checkPositiveAction(
                      data,
                      status,
                      sub_status
                    ),
                  }"
                >
                  {{ data.label }}
                </a>
              </CDropdown>
            </div>
            <div class="d-flex info-section">
              <span
                class="d-flex align-items-end"
                v-if="[4, 3].includes(reference_type_id)"
              >
                <i
                  class="fa-solid fa-circle-info"
                  v-c-tooltip="{
                    content: `${matched_comments}`,
                    appendToBody: true,
                  }"
                ></i>
              </span>
            </div>
            <div
              class="content-job-title cursor-action p-2 f-12"
              @click="getToJobDetails(index)"
            >
              <div class="job-content">
                <div class="image-wrapper">
                  <img
                    class="rounded-circle"
                    style="width: 65px"
                    :src="getOrganisationLogo(job)"
                    @error="
                      $event.target.src = '/img/hospital.png';
                      $event.target.style = 'width:50px';
                    "
                  />
                </div>
                <b class="d-block">{{ job.job_title }}</b>
                <span class="d-block">
                  <span class="text-muted">ID:</span>
                  <!-- {{ job.job_id }} -->
                  {{ job.display_uid }}
                </span>
                <span class="d-block"></span>
                <span class="d-block text-muted" v-if="job.organisation.name">{{
                  job.organisation.name
                }}</span>
                <span class="text-muted">
                  <span class="icon-location"></span>
                  {{ getHospitalLocation(job) }}
                </span>
              </div>
              <div class="text-left mb-3 ml-2" style="font-size: medium">
                <CBadge
                  color="success"
                  class="font-weight-normal ml-1"
                  v-if="job_applied"
                >
                  <span>Applied</span></CBadge
                >
                <CBadge color="primary" class="font-weight-normal ml-1" v-else
                  ><span>Talent Pool</span></CBadge
                >
                <CBadge
                  :color="getStatusColor(status_id)"
                  class="font-weight-normal ml-1 text-white"
                  v-if="status && status['display_text']"
                  ><span>{{ status["display_text"] }}</span></CBadge
                >
                <CBadge
                  :color="getStatusColor(sub_status_id)"
                  class="font-weight-normal ml-1 text-white"
                  v-if="
                    sub_status &&
                    sub_status['display_text'] &&
                    !getFavourite(index)
                  "
                  ><span>{{ sub_status["display_text"] }}</span></CBadge
                >
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <span v-else-if="isMatchedTab" class="text-center">
      <p class="text-gray h5">No Job Matches Found</p>
    </span>
    <p v-else class="text-gray h5 text-center">No Jobs Found</p>
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope, getPositiveActionIds } from "@/helpers/helper";
export default {
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    selectedJob: {
      type: Object,
      default: () => null,
    },
    tabInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isMobile: false,
      currentUserRole: getScope(),
      Role,
    };
  },
  computed: {
    jobsInfo() {
      return (this.jobs && this.jobs.map(({ job }) => job)) || [];
    },
    isMatchedTab() {
      return this.tabInfo?.tab_id === 1 || false;
    },
  },
  methods: {
    isPendingJob(job) {
      return job?.job_status?.status_id != 29 || false;
    },
    getToJobDetails(index) {
      let jobInfo = this.jobs[index];
      this.$emit("jobSelect", jobInfo);
    },
    getOrganisationType(data) {
      return data.organisation?.organisation_type?.organisation_type || "--";
    },
    getOrganisationLogo(data) {
      return this.isCandidateSupplierFromAccessToken
        ? data?.parent_org_info.length && data?.parent_org_info[0].logo_url
        : data.organisation?.logo_url || "/img/hospital.png";
    },
    isSelectedJob(jobID) {
      if (jobID && this.selectedJob) return jobID === this.selectedJob.job_id;
      return false;
    },
    getHospitalLocation(data) {
      return data?.location?.location || "--";
    },
    getActions(actions) {
      return (
        (actions &&
          actions.map((val) => {
            return {
              label: val?.name,
              action_id: val?.id,
              directAction: val?.direct_action,
              status: val?.status,
              filter_id: val?.filter,
              tab_id: val?.tab,
              reason_id: val?.reason,
            };
          })) ||
        []
      );
    },
    openPopup({ index, data }) {
      const jobDetails = this.jobs[index];
      let payload = { jobDetails, selectedAction: data };
      this.$emit("actionModalEvent", payload);
    },
    getMatched(index) {
      const jobInfo = this.jobs[index];
      return jobInfo?.status_id === 42;
    },
    getFavourite(index) {
      const jobInfo = this.jobs[index];
      return jobInfo?.status_id === 42 && jobInfo?.sub_status_id === 70;
    },
    addToFavourite(index) {
      const jobDetails = this.jobs[index];
      const selectedAction = {
        directAction: true,
        status: { update_status: 42, update_sub_status: 70 },
      };
      let payload = { jobDetails, selectedAction };
      this.$emit("actionModalEvent", payload);
    },
    removeFromFavourite(index) {
      const jobDetails = this.jobs[index];
      const selectedAction = {
        directAction: true,
        status: { update_status: 42, update_sub_status: null },
      };
      let payload = { jobDetails, selectedAction };
      this.$emit("actionModalEvent", payload);
    },
    checkPositiveAction(action, status, sub_status) {
      const { action_id } = action;
      const status_id = status?.status_id;
      const sub_status_id = sub_status?.status_id;
      const actionIds =
        getPositiveActionIds({ status_id, sub_status_id }) || [];
      return actionIds.includes(action_id);
    },
    getStatusColor(statusId) {
      switch (statusId) {
        case 39:
          return "success";
        case 42:
          return "matched";
        case 68:
          return "info";
        case 54:
          return "interview-status";
        case 62:
          return "onboarding-status";
        case 52:
          return "submitted-status";
        case 58:
          return "low";
        case 69:
          return "approved-onboarding";
        case 57:
          return "interview-completed";
        case 55:
          return "warning";
        case 53:
          return "awaiting-feedback";
        case 48:
          return "withdrawn";
        case 77:
          return "possible";
        case 41:
          return "manual";
        case 76:
          return "definite";
        case 71:
          return "inreview";
        case 59:
          return "awaiting-reponse";
        case 63:
          return "joined";
        case 47:
          return "disapproved";
        case 67:
          return "interview";
        case 49:
          return "reserve";
        case 51:
          return "interview-requested";
        case 50:
          return "interview-approved";
        case 56:
          return "rescheduled";
        case 60:
          return "success";
        case 86:
          return "low-priority";
        case 85:
          return "medium-priority";
        case 84:
          return "high-priority";
        default:
          return "info";
      }
    },
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
  },
  filters: {
    expiry(date) {
      if (date != null) {
        let now = moment().utc();
        let exp = moment().utc(date);
        return now.isAfter(exp) ? "Expired" : "Active";
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.job-card {
  border-radius: 11px;
  border: 0px !important;
  box-shadow: 0px 1px 3px 1px #79797926;
}
.job-content {
  line-height: normal;
}
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 50px;
  }
}
.cursor-action {
  cursor: pointer;
}
.content-job-title > .job-content {
  min-height: 210px;
}
.job-card:hover {
  background-color: #fdf1f64d;
}
.job-card.job-active {
  background-color: #fdf1f6;
  box-shadow: 0 4px 8px 0 rgba(253, 241, 246, 0.15),
    0 6px 20px 0 rgba(253, 241, 246, 0.14);
}
.action-icon {
  position: absolute;
  top: 1.24em;
  right: 1.4em;
  z-index: 100;
}
.info-section {
  position: absolute;
  top: 1.24em;
  left: 1.4em;
  z-index: 100;
}
.cursor-action {
  cursor: pointer;
}
.disabled-menu {
  color: #a9a9a9;
  cursor: default;
}
.job-pending {
  background-color: #ede297 !important;
}
// .cursor-action:hover {
//   text-decoration: underline;
// }
.positive-action {
  background-color: lightgreen;
}
</style>
